import type { ReadProjectType } from '@readme/api/src/mappings/project/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

type APINextModules = ReadProjectType['data']['appearance']['navigation']['links'];
type DBModules = Partial<ProjectClientSide['modules']>;

export const keyDictionary: Record<keyof APINextModules, keyof DBModules> = {
  home: 'landing',
  guides: 'docs',
  reference: 'reference',
  recipes: 'tutorials',
  changelog: 'changelog',
  discussions: 'discuss',
  graphql: 'graphql',
};

/**
 * Maps the Project store's `appearance.navigation.links` to an object that
 * matches the the shape of `project.modules` in the database.
 */
function normalizeProjectStoreModules(modules: APINextModules) {
  if (!modules) return {};

  const keys = Object.keys(modules) as (keyof typeof modules)[];
  const normalizedModules: DBModules = {};

  keys.forEach(key => {
    const normalizedKey = keyDictionary[key];
    if (normalizedKey) {
      normalizedModules[normalizedKey] = modules[key].visibility === 'enabled';
    }
  });

  return normalizedModules;
}

export default normalizeProjectStoreModules;
