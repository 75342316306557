import type { NavigationRepresentationType } from '@readme/api/src/mappings/project/types';

import { NavigationPageType } from '@readme/iso';

/**
 * Maps the 'type' property of a project store nav item to the value used in the database.
 */
export function normalizeProjectStoreNavItemType(type: string) {
  let navType;

  switch (type) {
    case 'changelog':
      navType = NavigationPageType.CHANGELOG;
      break;
    case 'custom_page':
      navType = NavigationPageType.CUSTOM_PAGE;
      break;
    case 'discussions':
      navType = NavigationPageType.DISCUSSIONS;
      break;
    case 'guides':
      navType = NavigationPageType.GUIDES;
      break;
    case 'home':
      navType = NavigationPageType.HOME;
      break;
    case 'link_url':
      navType = NavigationPageType.LINK_URL;
      break;
    case 'recipes':
      navType = NavigationPageType.RECIPES;
      break;
    case 'reference':
      navType = NavigationPageType.API_REFERENCE;
      break;
    case 'search_box':
      navType = NavigationPageType.SEARCH_BOX;
      break;
    case 'user_controls':
      navType = NavigationPageType.USER_CONTROLS;
      break;
    default:
      navType = type;
      break;
  }
  return navType;
}

/**
 * Maps items in the project store `appearance.navigation.top`, `appearance.navigation.left` and
 * `appearance.navigation.sub_nav` arrays to have properties with the values used the database, which
 * we use in the Hub routing and navigation UI logic and styling.
 */
function normalizeProjectStoreNavList(list: NavigationRepresentationType[] | null) {
  return [...(list || [])].map(item => ({
    url: item.url,
    text: item.title,
    page: item.custom_page,
    type: normalizeProjectStoreNavItemType(item.type),
  }));
}

export default normalizeProjectStoreNavList;
