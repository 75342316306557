import { useContext } from 'react';

import { ProjectContext, UserContext } from '@core/context';
import { useProjectStore } from '@core/store';

import { normalizeProjectStoreNavLabels } from './normalizeProjectStoreNavLabels';

const useFeatureOptions = () => {
  const { project } = useContext(ProjectContext);
  const { flags } = project;
  const { user } = useContext(UserContext) || {};
  const moduleNames = useProjectStore(s => normalizeProjectStoreNavLabels(s.data.appearance.navigation.links));

  // TODO-HUB1-MIGRATION: remove this once projects are all upgraded
  // This was renamed from blog to changelog in hub2
  if (moduleNames.blog) {
    moduleNames.blog = '';
  }

  // The joys of switching names halfway through the project
  // (See below in the `return` statement for more details!)
  if (moduleNames.recipes) {
    moduleNames.tutorials = moduleNames.recipes;
  }

  return {
    home: 'Home', // for the header
    landing: 'Home', // for the subnav
    docs: 'Guides',

    // Why both?
    // See the comments in models/projects/index.js under `nav_names` to
    // understand what we have to do to deprecate both of these!
    //
    // Also: https://github.com/readmeio/readme/pull/8965#discussion_r1170694747
    tutorials: 'Recipes', // for the subnav
    recipes: 'Recipes', // for the header

    reference: 'API Reference',
    ...(flags?.graphql === true && { graphql: 'GraphQL' }),
    logs: 'API Logs',
    changelog: 'Changelog',
    discuss: 'Discussions',
    user: (user && user.name) || false,
    ...Object.keys(moduleNames).reduce((all, key) => {
      const val = moduleNames[key] || false;
      if (val) all[key] = val;
      return all;
    }, {}),
  };
};

export default useFeatureOptions;
