import type { ReadProjectType } from '@readme/api/src/mappings/project/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import { keyDictionary } from '@Hub/Header/utils/normalizeProjectStoreModules';

type APINextModules = ReadProjectType['data']['appearance']['navigation']['links'];
type DBNavNames = Partial<ProjectClientSide['nav_names']>;

/**
 * Maps the Project store's `appearance.navigation.links` to an object that
 * matches the the shape of `project.nav_names` in the database.
 */
export function normalizeProjectStoreNavLabels(modules: APINextModules) {
  // Home and GraphQL are not able to be aliased.
  const keys = Object.keys(modules).filter(key => !['home', 'graphql'].includes(key));
  const normalizedAliases: DBNavNames = {};

  keys.forEach(key => {
    const normalizedKey = keyDictionary[key];
    if (normalizedKey) {
      normalizedAliases[normalizedKey] = modules[key]?.alias;
    }
  });

  return normalizedAliases;
}
